import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import TimePeriodSelector from "../Miscellaneous/TimePeriodSelector"
import { useEffect, useState } from "react";
import { LiveVehicle } from "../../types";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useFirstLoad } from "../../hooks/useSkeloton";
import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment";
import VehicleCard from "./VehicleInfoCard";
import DTCChips from "./DtcChips";
import VehicleDtcHistoryChart from "./DTCHistoryGraph";
import VehicleLampHistoryChart from "./LampHistoryGraph";
import VehicleDtcHistoryTable from "./VehicleDtcHistoryTable";
import dayjs from "dayjs";


// Query to fetch vehicle live fault code data
const GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY = gql`
  query GetVehicleLiveFaultCodeData($uniqueId: String!) {
    getVehicleLiveFaultCodeData(uniqueId: $uniqueId) {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type
      fault_type_hex
      dtcsae
      hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
      fuel_type
      severity
    }
  }
`;

// Query to fetch all vehicle live statuses
const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const GET_VEHICLE_SALES_DATA = gql`
query GetSalesData($vin: [String!]) {
  getSalesData(vin: $vin) {
    chasisNo
    engineNo
    applicationType
    modelCategory
    productName
    vehicleType
    gvw
    passengerCapacity
    wheelBase
    engineType
    brakeType
    emissionStandard
    dealerCode
    dealerContactName
    dealerContactNumber
    fuelType
    adblueTankCapacity
    yearOfManufacturing
  }
}`;

const GET_VEHICLE_HISTORY_DTC_LAMPS = gql`
query GetHistoricFaultCodes($uniqueId: String!, $fromTs: Int!, $toTs: Int!) {
  getHistoricFaultCodes(uniqueId: $uniqueId, fromTs: $fromTs, toTs: $toTs) {
    date
    dtc_codes
    lamps
    odometer
    hourmeter
    dpf {
      ts
      odo
      hrm
    }
    startOdometer
    startHourmeter
    endOdometer
    endHourmeter
  }
}`

const VehicleDiagnosticsPage = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(location.search);
  const queryUniqueId = queryParams.get("uniqueId");
  const autoFetch = queryParams.get("autoFetch") === "true";

  // Lazy queries
  const [getVehicleLiveStatusAndFaultHistory, { data: queryData, loading: queryLoading, error: queryError }] = useLazyQuery(GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY, {
    fetchPolicy: "network-only",
  });

  const [getSalesData, { data: salesData, loading: salesLoading, error: salesError }] = useLazyQuery(GET_VEHICLE_SALES_DATA);

  const [getDTCHistory, { data: historyData, loading: historyLoading, error: historyError }] = useLazyQuery(GET_VEHICLE_HISTORY_DTC_LAMPS);

  // State hooks
  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<LiveVehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<LiveVehicle | null>(null);
  const [fromTime, setFromTime] = useState<number>(Math.floor(new Date().setHours(0, 0, 0, 0) / 1000));
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, setToTime] = useState<number>(Math.floor((Date.now() - 2 * 24 * 60 * 60 * 1000) / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [customerData, setCustomerData] = useState<any>(null);
  const { isFirstLoad, markLoaded, resetFirstLoad } = useFirstLoad();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [committedFromTime, setCommittedFromTime] = useState(fromTime);
  const [committedToTime, setCommittedToTime] = useState(toTime);

  // Query for vehicle live status
  const [getAllVehicleLiveStatus, { loading: liveVehicleDataLoading, data: liveVehicleData, error: errorLiveVehicleData }] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });

  // Fetch all live vehicle statuses on component mount
  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [getAllVehicleLiveStatus]);

  // Automatically set the selected vehicle if queryUniqueId is present
  useEffect(() => {
    if (queryUniqueId && allVehicleLiveStatus.length > 0) {
      const vehicle = allVehicleLiveStatus.find(v => v.uniqueId === queryUniqueId);
      if (vehicle) {
        setSelectedVehicle(vehicle);
      }
    }
  }, [queryUniqueId, allVehicleLiveStatus]);

  // Handle option changes and update the time range
  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    if (fromTime && fromTime === toTime) {
      // Adjust to full day if fromTime and toTime are the same
      const adjustedFromTime = dayjs.unix(fromTime).startOf("day").unix(); // Start of the day
      const adjustedToTime = dayjs.unix(toTime).endOf("day").unix(); // End of the day
      setFromTime(adjustedFromTime);
      setToTime(adjustedToTime);
    }
    if (option !== "CUSTOM" && option !== "") {
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      setToTime(currentTimeInSeconds - 2 * 24 * 60 * 60);
      switch (option) {
        case "HOUR":
          setFromTime(currentTimeInSeconds - 60 * 60); // Last 1 hour
          break;
        case "DAY":
          setFromTime(currentTimeInSeconds - 24 * 60 * 60); // Last 1 day
          break;
        case "WEEK":
          setFromTime(currentTimeInSeconds - 7 * 24 * 60 * 60); // Last 7 days
          break;
        case "MONTH":
          setFromTime(currentTimeInSeconds - 30 * 24 * 60 * 60); // Last 30 days
          break;
        default:
          break;
      }
    }
  }, [fromTime, option, toTime]);

  // Fetch vehicle fault history and sales data when the selected vehicle changes
  useEffect(() => {
    if (selectedVehicle && autoFetch) {
      getVehicleLiveStatusAndFaultHistory({ variables: { uniqueId: selectedVehicle.uniqueId, fromDate: committedFromTime } });
      getDTCHistory({
        variables: { uniqueId: selectedVehicle.uniqueId, fromTs: committedFromTime, toTs: committedToTime },
        onCompleted: () => setLoadingScreen(false),
      });
      if (selectedVehicle.chassisNumber) {
        getSalesData({ variables: { vin: [selectedVehicle.chassisNumber] } });
      }
    }
  }, [selectedVehicle, autoFetch, committedFromTime, committedToTime, getVehicleLiveStatusAndFaultHistory, getDTCHistory, getSalesData]);

  // Set customer data when sales data is fetched
  useEffect(() => {
    if (salesData && salesData.getSalesData) {
      setCustomerData(salesData.getSalesData[0]); // Assuming getSalesData returns an array
    }
  }, [salesData]);

  useEffect(() => {
    if (!queryLoading && !salesLoading && !historyLoading) {
      markLoaded();
      resetFirstLoad();
    }
  }, [queryLoading, salesLoading, historyLoading, markLoaded, resetFirstLoad]);

  useEffect(() => {
    if (selectedVehicle && autoFetch) {
      setCommittedFromTime(fromTime);
      setCommittedToTime(toTime);
    }
  }, [selectedVehicle, autoFetch, fromTime, toTime]);

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    setOption(e.target.value);
    setCustomerData(null);
    setLoadingScreen(true);
    setSubmitClicked(false);
  };



  const handleSubmit = () => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
    } else if (
      (fromTime && fromTime > moment(moment.now()).unix()) ||
      toTime > moment(moment.now()).unix()
    ) {
      enqueueSnackbar("Future dates are not allowed");
    } else {
      setFromTime(fromTime);
      setToTime(toTime);
      setLoadingScreen(false); // Show loader during data fetch
      setSubmitClicked(true); // Trigger data fetching on submit  
      if (selectedVehicle) {
        setCommittedFromTime(fromTime);
        setCommittedToTime(toTime);
        getVehicleLiveStatusAndFaultHistory({
          variables: { uniqueId: selectedVehicle.uniqueId, fromDate: fromTime },
          onCompleted: () => setLoadingScreen(false), // Hide loader after completion
        });
        getDTCHistory({
          variables: { uniqueId: selectedVehicle.uniqueId, fromTs: fromTime, toTs: toTime },
          onCompleted: () => setLoadingScreen(false), // Hide loader after completion
        });
        if (selectedVehicle.chassisNumber) {
          getSalesData({
            variables: { vin: [selectedVehicle.chassisNumber] },
            onCompleted: () => setLoadingScreen(false), // Hide loader after completion
          });
        }
      }
    }
  };

  const handleSelectVehicle = (event: React.ChangeEvent<{}>, newValue: LiveVehicle) => {
    setSelectedVehicle(newValue);
    setCustomerData(null); // Clear customer data
    setLoadingScreen(true); // Show loader until user submits
    setSubmitClicked(false); // Reset submit clicked
  };

  const vehicleData = queryData?.getVehicleLiveFaultCodeData;
  const DTCHistory = historyData?.getHistoricFaultCodes;

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 2 }}>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={8}>
          <TimePeriodSelector
            option={option}
            setFromTs={setFromTime}
            fromTs={fromTime}
            toTs={toTime}
            setToTs={setToTime}
            onOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
            handleSelectVehicle={handleSelectVehicle}
            vehicles={allVehicleLiveStatus}
            isDefaultSelected={isDefaultSelected}
            selectedVehicle={selectedVehicle}
            isDashBoard={false}
            isDownload={false}
          />
        </Grid>
      </Grid>

      {loadingScreen ? (
        <Box textAlign="center">
        </Box>
      ) : (
        <Grid container spacing={1}>
          {!selectedVehicle ? (
            autoFetch ? (
                      <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                        <CircularProgress />
                      </Box>
            ) : (
              <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                <Typography textAlign="center">
                  You have not selected any vehicle. Please select a vehicle.
                </Typography>
              </Grid>
            )
          ) : (
            <>
              <Grid item xs={12}>
                {salesLoading ? (
                  <Box textAlign="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  selectedVehicle && (
                    <VehicleCard
                      key={selectedVehicle.uniqueId}
                      vehicle={selectedVehicle}
                      customerData={customerData}
                    />
                  )
                )}
              </Grid>
              <Grid item xs={12}>
                {queryLoading ? (
                  <Box textAlign="center">
                    <CircularProgress />
                  </Box>
                ) : vehicleData ? (
                  <DTCChips faultCodeData={vehicleData} />
                ) : (
                  <Typography textAlign={"center"}>No fault code history available</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {historyLoading ? (
                  <Box textAlign="center">
                    <CircularProgress />
                  </Box>
                ) : DTCHistory ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <VehicleDtcHistoryChart historyData={DTCHistory} />
                    </Grid>
                    <Grid item xs={6}>
                      <VehicleLampHistoryChart historyData={DTCHistory} />
                    </Grid>
                  </Grid>
                ) : (
                  <Typography textAlign={"center"}>No fault code history available</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {historyLoading ? (
                  <Box textAlign="center">
                    <CircularProgress />
                  </Box>
                ) : DTCHistory ? (
                  <VehicleDtcHistoryTable historyData={DTCHistory} />
                ) : (
                  <Typography>No history available</Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}


export default VehicleDiagnosticsPage;